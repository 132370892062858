import { isMobileOnly } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, showcaseData } from "../../data";
import "../../styles/showcase.css";

export default function Showcase() {
  return (
    <section
      className="relative z-10 flex flex-col items-center justify-center bg-dark-orange w-screen h-[80vh] pb-10  w-small-laptop:pb-14"
      id="showcase"
    >
      <h2 className=" flex items-center justify-center text-2xl text-themed-orange w-full text-center pb-7 px-9 opacity-90 ipad:px-20 ipad:pb-10 ipad:before:content-[''] ipad:before:flex-1 ipad:before:h-[1px] ipad:before:bg-gradient-to-l ipad:before:from-themed-orange ipad:before:to-transparent ipad:before:mr-2 ipad:after:content-[''] ipad:after:flex-1 ipad:after:h-[1px] ipad:after:bg-gradient-to-r ipad:after:from-themed-orange ipad:after:to-transparent ipad:after:ml-2 w-small-laptop:pb-14  w-small-laptop:px-44 w-big-laptop:px-56 w-desktop:px-96">
        How does Resturo work?
      </h2>
      <Carousel
        responsive={responsive}
        arrows
        minimumTouchDrag={isMobileOnly ? 80 : 160}
        containerClass="w-full h-auto z-20"
      >
        {showcaseData.map((data) => {
          return (
            <div
              key={data.id}
              className="flex flex-row justify-around items-center h-auto mx-auto relative px-9 ipad:px-20 w-small-laptop:px-44 w-big-laptop:px-56 w-desktop:px-96"
            >
              {!isMobileOnly && (
                <img
                  className="w-auto pr-20 pointer-events-none ipad:h-[30vh] w-small-laptop:h-[50vh] md:pr-10 lg:h-[70vh]"
                  src={data.imgSrc}
                  alt={data.imgAlt}
                />
              )}

              <div className="flex flex-col text-left text-default-white">
                <h2 className="font-bold border-b border-default-white pb-4 text-4xl opacity-90 text-center ipad:text-left md:text-6xl">
                  <span className="text-themed-orange">{data.span} </span>
                  {data.heading}
                </h2>
                {isMobileOnly ? (
                  <img
                    className="h-auto w-1/2 pointer-events-none mx-auto mt-5"
                    src={data.imgSrc}
                    alt={data.imgAlt}
                  />
                ) : (
                  <p className="pt-3 opacity-90 md:pt-5">{data.paragraph}</p>
                )}
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
}
